import * as React from "react"
import { interpolateString } from "../helpers/Strings"
import MathResourceDrawer from "../components/MathResourceDrawer"
import { graphql, Link } from "gatsby"
import Cta from "../components/CTA"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Routes } from "../data/routes"

const Cover = "../images/math-subject-cover.jpeg"
const Content = {
  description:
    "Yup tutors are experts in {{subject}}. Click to learn more about the concept.",
  header:
    "Yup tutors are experts in {{subject}}. Click to learn more about the concept.",
  cta: "Our tutors are standing by 24/7 to support you",
  actions: [
    {
      link: "#",
      text: "Get started",
    },
  ],
}

function capitalize(title) {
  return title.charAt(0).toUpperCase() + title.slice(1)
}

function getTitle(title) {
  return title.split("-").map(capitalize).join(" ")
}

function getSubjectTitle(title) {
  return title.split("-").map(capitalize).join("-")
}

function Header(props) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-6364cb4174b8dfdfbd7fa71ac72ab862
  return (
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2" />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <StaticImage
              className="h-full w-full"
              src={Cover}
              alt={getSubjectTitle(props.id)}
            />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-white">
              {getSubjectTitle(props.id)}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

function TopicsSection(props) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-482ea288d556374012ca6eed5391c5f0
  return (
    <div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            {interpolateString(Content.header, {
              subject: getSubjectTitle(props.id),
            })}
          </h2>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {props.resources.map(resource => (
            <Link
              key={resource.frontmatter.title}
              to={resource.fields.slug}
              className="relative"
            >
              <dt>
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900 py-4">
                  {getTitle(resource.frontmatter.title)}
                </p>
              </dt>
            </Link>
          ))}
        </dl>
      </div>
    </div>
  )
}

const MathSubjectTemplate = ({ pageContext, data, location }) => {
  const resources = data.allMarkdownRemark.nodes.filter(resource =>
    resource.frontmatter.subjects.includes(pageContext.id)
  )

  return (
    <Layout location={location}>
      <Seo
        title={getSubjectTitle(pageContext.id)}
        route={`${Routes.mathResources}${pageContext.id}`}
      />
      <div className="sm:flex max-w-7xl mx-auto">
        <MathResourceDrawer />
        <div className="flex-grow">
          <Header id={pageContext.id} />
          <TopicsSection id={pageContext.id} resources={resources} />
          <Cta title={Content.cta} actions={Content.actions} />
        </div>
      </div>
    </Layout>
  )
}

export default MathSubjectTemplate

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { fields: { collection: { eq: "math-resources" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          subjects
        }
      }
    }
  }
`
